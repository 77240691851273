import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
const routes: Routes = [
  {
    path: "",
   loadChildren:()=>import('./components/home/home.module').then(m=>m.HomeModule), data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "team-science-grants",
    loadChildren: () => import('./components/india-alliance-grants/india-alliance-grants.module').then(m => m.IndiaAllianceGrantsModule)
    , data: {title: 'Team Science Grants - India Alliance'}
  },
  {
    path: "clinical-public-health-research-centres",
    loadChildren: () => import('./components/india-alliance-grants/india-alliance-grants.module').then(m => m.IndiaAllianceGrantsModule)
    , data: {title: 'Clinical & Public Health Research Centres - India Alliance'}
  },
  {
    path: "clinical-public-health-research-training-programme",
    loadChildren: () => import('./components/india-alliance-grants/india-alliance-grants.module').then(m => m.IndiaAllianceGrantsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "margdarshi-fellowships",
    loadChildren: () => import('./components/india-alliance-grants/india-alliance-grants.module').then(m => m.IndiaAllianceGrantsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "applicants",
    loadChildren: () => import('./components/applicants/applicants.module').then(m => m.ApplicantsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellows",
    loadChildren: () => import('./components/fellows/fellows.module').then(m => m.FellowModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellow/:Fellow_URL",
    loadChildren: () => import('./components/fellows/fellow/fellow.module').then(m => m.FellowDetailModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "news",
    loadChildren: () => import('./components/news-section/news/news.module').then(m => m.NewsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "news/:Post_URL",
    loadChildren: () => import('./components/news-section/news-detailview/news-detailview.module').then(m => m.NewsDetailviewModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "about-us",
    loadChildren: () => import('./components/about-us-section/about-us/about-us.module').then(m => m.AboutUsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "contact",
    loadChildren:()=>import('./components/contact/contact.module').then(m=>m.ContactModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "events",
    loadChildren:()=>import('./components/events/events.module').then(m=>m.EventsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "event/:Event_URL",
   loadChildren:()=>import('./components/events/event/event.module').then(m=>m.EventModule)
   , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "career",
    loadChildren: () => import('./components/career/career.module').then(m => m.CareerModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "careerdetails/:JobID",
    loadChildren: () => import('./components/career/careerdetail/careerdetail.module').then(m => m.CareerdetailModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "archive",
    loadChildren: () => import('./components/career/archive/archive.module').then(m => m.CareerdetailModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "public-engagement",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "early-career-fellowships",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "management-funding",
    loadChildren: () => import('./components/research-management-funding/research-management-funding.module').then(m => m.ResearchManagementFundingModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "ip-policy",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "india-science-media-fellowships",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "privacy",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "leadership-course",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "recently-recommended-awardees",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "archives-careers",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "india-embo-lecture-courses",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "developing-indian-physician-scientist-workshops",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellowshipdeadlines",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellows-corner",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "disclaimer",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "about-iasys",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "iasys-faqs",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellowships-at-a-glance",
    loadChildren: () => import('./components/applicants/applicants.module').then(m => m.ApplicantsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "science-communication-workshop",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "india-research-management-initiative",
    loadChildren: () => import('./components/irm-initiative/irm-initiative.module').then(m => m.IrmInitiativeModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "workshops-outreach",
    loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
 
  
  // 
  //   path: "india-research-management-initiative",
  //   loadChildren: () => import('./components/terms-page/terms-page.module').then(m => m.TermsPageModule)
  //   , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  // },
  {
    path: "newsletters",
    loadChildren: () => import('./components/newsletters/newsletters.module').then(m => m.NewslettersModule)
    , data: {title: 'India Alliance Newsletters'}
  },
  {
    path: "videos",
    loadChildren: () => import('./components/videos-section/videos-section.module').then(m => m.VideosSectionModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "fellowshiptype",
    loadChildren: () => import('./components/fellow-ship-type/fellow-ship-type.module').then(m => m.FellowShipTypeModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "search-results",
    loadChildren: () => import('./components/search-results/search-results.module').then(m => m.SearchResultsModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },
  {
    path: "year-wise-awards",
    loadChildren: () => import('./components/year-wise-awards-list/year-wise-awards-list.module')
    .then(m => m.YearWiseAwardsListModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  },  
  {
    path: "**",
   loadChildren:()=>import('./components/router-handler-component/router-handler-component.module').then(m=>m.RouterHandlerComponentModule)
    , data: {title: 'India Alliance - Advancing Discovery & Innovation to Improve Health'}
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
