import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  collpasstyle_new = {
    background: "#dedcdc",
    "border-radius": "4px",
    "margin-bottom": "10px",
    width: "100%",
    border: "none",
    "text-align": "left",
  };
  isMainContent
  Post_Type = {
    1: 'Funding',
    2: 'Announcement',
    3: 'Research',
    4: 'Interview',
    5: 'Newsletter',
    6: 'Event',
    7: 'Article',
    8: 'Report',
  }
  Grantee_Type = {
    1: 'Team Science Grants',
    2: 'Clinical & Public Health',
  }
  fellowShipTyp = {
    'Basic Biomedical Research Fellowships': 'Basic Biomedical Research Fellow',
    'Clinical & Public Health Research Fellowships': 'Clinical & Public Health Research Fellow',
    'India Research Management Initiative (IRMI)': 'India Research Management Initiative'
  }
  fellowCatTitle = {
    'Intermediate Fellowship': 'Intermediate Fellow',
    'Early Career Fellowship': 'Early Career Fellow',
    'Senior Fellowship': 'Senior Fellow',
    'RM Fellowships': 'RM Fellowship',
    'RM Grants': 'RM Grant',
    'RM Travel Grants': 'RM Travel Grant',
    'RM Travel Grants​': 'RM Travel Grants',
  }
  collpasstyle = {
    background: '#dedcdc',
    'border-radius': '4px',
    'margin-bottom': '10px',
    width: '100%',
    border: 'none',
    'text-align': 'left',
    'padding-left': '10px'
  }
  Fellowship_Type_Base_URL = `https://www.indiaalliance.org/fellowtype/`;
  Fellowship_Category_Base_URL = `https://www.indiaalliance.org/fellowcategory/`;
  Fellow_Base_URL = `https://www.indiaalliance.org/fellow/`;
  Page_Base_URL = `https://www.indiaalliance.org/page/`;
    public readonly baseUrl = 'https://api.indiaalliance.org/web/'
    public readonly uploadUrl = 'https://api.indiaalliance.org/upload/';
  // public readonly baseUrl = 'http://localhost:3000/web/'
  // public readonly uploadUrl = 'http://localhost:3000/upload/';

  limit: number = 10
  pageSizes = [
    1, 10, 20, 30, 40
  ]
  onChangeLanguageEmitter = new EventEmitter()
  googleKey = 'AIzaSyAS2Wjfa9giURmUkEq8pQaZq75LwIcHH5w'  //meraj@vixspace.com
  url = `https://translation.googleapis.com/language/translate/v2?key=${this.googleKey}`;
  currentLanguage = 'en'
  constructor(private httpService: HttpClient) {
    // this.translate()
  }
  postMethod(posturl: string, body: any): Observable<any> {
    return this.httpService.post(this.baseUrl + posturl, body);
  }
  postMethodImage(posturl: string, body: any): any {
    return this.httpService.post(this.uploadUrl + posturl, body)
  }
  translate(obj) {
    return this.httpService.post(this.url, obj)
    // return this.httpService.post(this.url, {
    //   q: 'Meraj',
    //   target: 'hi'
    // })
  }
  onTranslate(labelsObj, currentLabelsObj) {
    this.translate({
      q: Object.values(labelsObj),
      target: this.currentLanguage
    }).subscribe((data: any) => {
      let tmpTranslateion = []
      tmpTranslateion = data.data.translations
      tmpTranslateion.forEach((item, index) => {
        currentLabelsObj[Object.keys(currentLabelsObj)[index]] = tmpTranslateion[index].translatedText
      })
    })
    return currentLabelsObj
  }
}
