import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { AppService } from "../app.service";
import { Router } from '@angular/router';
import { query } from '@angular/animations';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuIcon') menuIcon: ElementRef
  constructor(public _appservice: AppService, private router: Router,
    ) { }
  myQuery: string = ''
  selectedLanguage = this._appservice.currentLanguage
  labelsObj = {
    'Home': "Home",
    'Funding': 'Funding',
    'Research Funding': 'Research Funding',
    'India Alliance Fellowships': 'India Alliance Fellowships',
    'Basic Biomedical Research Fellowships': 'Basic Biomedical Research Fellowships',
    'Clinical and Public Health Research Fellowships': 'Clinical and Public Health Research Fellowships',
    'India Alliance Grants': 'India Alliance Grants',
    'Team Science Grants': 'Team Science Grants',
    'Clinical and Public Health Research Centres': 'Clinical and Public Health Research Centres',
    'Clinical Research Training Programme': 'Clinical Research Training Programme',
    'For Applicants': 'For Applicants',
    'Africa-India Mobility Fund': 'Africa-India Mobility Fund',
    'India Research Management Initiative': 'India Research Management Initiative',
    'Workshops & outreach':'Workshops & outreach',
    'finace':'finace',
    'Public Engagement': 'Public Engagement',
    'About Public Engagement': 'About Public Engagement',
    'India Science Media Fellowships': 'India Science Media Fellowships',
    'Science Communication Workshops': 'Science Communication Workshops',
    'Leadership Courses': 'Leadership Courses',
    'Developing Indian Physician Scientist Workshop': 'Developing Indian Physician Scientist Workshop',
    'Workshops & Courses': 'Workshops & Courses',
    'Videos': 'Videos',
    '10 years of India Alliance': '10 years of India Alliance',
    'India EMBO Lecture Courses': 'India EMBO Lecture Courses',
    'About Us': 'About Us',
    'Organisation': 'Organisation',
    'Media': 'Media',
    'Careers': 'Careers',
    'Contact Us': 'Contact Us',
    'India Alliance Fellows': 'India Alliance Fellows',
    'Brand Usage Guidelines': 'Brand Usage Guidelines',
    'Annual Reports': 'Annual Reports',
    'Staff and committee': 'Staff and committee',
    'Funded Areas': 'Funded Areas',
    'News': 'News',
    'Login to IASys': 'Login to IASys',
    'Newsletters': 'Newsletters',
  }
  currentLabelsObj = {}
  ngOnInit(): void {
    this.currentLabelsObj = { ... this.labelsObj }
    this.onTranslate()
  }
  onMenuLink() {
    this.menuIcon.nativeElement.click()
  }
  onSubmitQuery() {
    this.router.navigate(['search-results'], { queryParams: { searchValue: this.myQuery } })
  }
  onChangeLangulage(ev) {
    this.selectedLanguage = ev
    if (ev == 'en') {
      console.log('this.labelsObj', this.labelsObj)
      this.currentLabelsObj = { ... this.labelsObj }
    } else {
      this.onTranslate()
    }
    this._appservice.currentLanguage=this.selectedLanguage
    this._appservice.onChangeLanguageEmitter.emit(this.selectedLanguage)
  }
  onTranslate() {
    this._appservice.translate({
      q: Object.values(this.labelsObj),
      target: this.selectedLanguage
    }).subscribe((data: any) => {
      let tmpTranslateion = []
      tmpTranslateion = data.data.translations
      tmpTranslateion.forEach((item, index) => {
        this.currentLabelsObj[Object.keys(this.currentLabelsObj)[index]] = tmpTranslateion[index].translatedText
      })
    })
  }
}
export enum headerLabels {
  Home = 'Home',
}
